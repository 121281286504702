@import url('https://fonts.googleapis.com/css2?family=Abyssinica+SIL&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
:root {
  --primary: #295c44;
  --secondary: #a1dffe;
  --trinary: #DC3545;
  --quad: #c6d5e0;

  --cursive: 'Pinyon Script', cursive;
  --block: 'Roboto', sans-serif;
}

