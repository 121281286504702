.station-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.station-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 28rem;
  background-color: var(--secondary);
  border-radius: 10px;
  transition: 0.4s ease-out;
  margin: 2rem;
  padding: 2rem;
  text-decoration: none;
  background-image: url('../../assets/images/snowflake-background.jpg');
}

.station-card:hover {
  transform: translateY(-20px);
  transition: 0.4s ease-out;
  text-decoration: none;
  color: inherit;
}

.station-card:hover ~ .card {
  position: relative;
  left: 50px;
  transition: 0.4s ease-out;
  text-decoration: none;
}

.tune {
  text-align: center;
  margin-top: 3rem;
  color: var(--primary);
  font-family: var(--cursive);
  font-weight: 700;
  font-size: 4rem;
  text-decoration: underline;
}
a {
  color: var(--trinary);
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: var(--trinary);
}
button {
  background: none;
}
button:hover {
  background: none;
  text-decoration: none;
}

.station-name {
  font-family: var(--block);
  text-decoration: underline;
  color: var(--primary);
}

.station-location {
  font-family: var(--block);
  font-weight: 550;
  text-decoration: none;
  color: var(--primary);
}

.station-dates {
  display: flex;
  flex-direction: column;
  font-family: var(--block);
  font-weight: 500;
  border: 1px;
  text-decoration: underline;
  justify-content: center;
  align-items: center;
  color: var(--primary);
}
