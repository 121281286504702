.home-body {
  text-align: center;
  z-index: -2;
}
.home-body h2 {
  color: var(--trinary);
}

.home-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15rem;
}

.home-header {
  font-family: var(--cursive);
  font-size: 5rem;
  font-weight: 700;
}

.banner-image {
  width: 20rem;
  position: absolute;
  left: 30%;
  z-index: -1;
}

.carousel-container {
  width: 40%;
  margin: auto;
  border: 5px solid var(--trinary);
  overflow: hidden;
  position: relative;
  margin-bottom: 2%;
}

.carousel-slide {
  display: flex;
  width: 100%;
  height: 500px;
  transition: 0.4s ease-in-out;
}

#prevBtn {
  position: absolute;
  top: 50%;
  z-index: 10;
  left: 5%;
  font-size: 30px;
  color: white;
  cursor: pointer;
}

#nextBtn {
  position: absolute;
  top: 50%;
  z-index: 10;
  right: 5%;
  font-size: 30px;
  color: var(--primary);
  cursor: pointer;
}
.p-text {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  background-color: var(--primary);
  padding: 4rem;
  color: aliceblue;
  font-size: 1.2rem;
}

.shoutout {
  color: var(--trinary);
  font-size: 3rem;
  font-weight: 600;
  font-family: var(--cursive);
}

@media screen and (max-width: 600px) {
  .home-header {
    font-size: 4rem;
  }

  .p-text {
    border-radius: 40px;
    padding: 0.8rem;
  }

  .home-body {
    padding: 1.3rem;
  }

  .home-banner {
    margin-bottom: 4rem;
  }
}
