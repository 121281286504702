.biography {
  display: flex;
  margin: 3rem;
  align-items: center;
  justify-content: center;
}

.card {
  width: 30%;
  margin-right: 15px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: none;

  @media screen {
    
  }
}

.card-text {
  align-items: center;
  justify-content: center;
  background-color: var(--trinary);
  border-radius: 2%;
  font-family: var(--block);
  font-weight: 400;
  color: white;
}
.card-body {
  background-color: var(--primary);
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
}

.card-title {
  border-radius: 5%;
  font-family: var(--block);
  font-weight: bolder;
  margin-top: -12px;
}
a:hover {
  text-decoration: none;
  color: var(--trinary);
}

.cards {
  display: flex;
  justify-content: space-evenly;
}

.card-image {
  border-top-left-radius: 5%;
  border-top-right-radius: 5%;
  border: none;
  padding: 5px;
  background-color: var(--primary);
}

@media screen and (max-width: 800px) {
  .biography {
    display: flex;
    flex-direction: row;
    padding-left: 0px;
    padding-right: 0px;
  }
  .bio-text {
    width: 90%;
    padding: 10px;
    text-align: center;
  }
  .card {
    margin-right: 0px;
  }
  .cards {
    flex-direction: column;
  }
}
