.contact-title {
  font-family: var(--cursive);
  font-weight: 700;
  font-size: 3rem;
  color: var(--primary);
  text-align: center;
  text-decoration: none;
}

.contact-item {
  margin: 3rem;
  color: var(--trinary);
  text-align: center;
}
