.photo {
  width: 40vw;
  margin: 2rem;
}
.title {
  text-decoration: underline;
  font-family: var(--cursive);
  margin: 3rem;
}
.buttons {
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: center;
}

.media {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
  text-align: center;
}

.media video,
.media img {
  max-height: 300px;
  max-width: 400px;
  min-height: 300px;
  min-width: 300px;
}
