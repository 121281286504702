h5 {
  text-align: center;
  color: var(--primary);
  font-family: var(--cursive);
  font-weight: 700;
  font-size: 2rem;
  color: var(--trinary);
  margin-top: 25px;
}

.sponsor-links {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
  font-family: var(--block);
  font-weight: bold;
}

.sponsor-links a {
  text-decoration: none;
  color: var(--trinary);
}

@media screen and (max-width: 600px) {
  .sponsor-links {
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .sponsor-links a {
    margin: auto;
    text-align: center;
    border: 1px solid;
    border-radius: 10px;
    padding: 5px;
    margin: 10px;
  }
}
