.hour-buttons {
  margin: 1rem;
  text-align: center;
}

.btn {
  background-color: var(--trinary);
}

.hour-info {
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#hourHeader {
  font-family: var(--cursive);
  font-size: 4rem;
  font-weight: 700;

  color: var(--trinary);
}

.hour-section {
  border-radius: 40px;
  padding: 2rem;
  width: 75%;
  margin: auto;
  font-family: var(--cursive);
  font-size: 2rem;
  font-weight: 700;
  color: var(--primary);
}

.coming-soon {
  color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  margin-top: 15px;
}
.coming-soon-h1 {
  font-family: var(--cursive);
  font-size: 4rem;
  text-decoration: underline;
  text-align: center;
  font-weight: 700;
}

@media screen and (max-width: 600px) {
  .btn {
    font-size: 0.7rem;
  }
  .hour-section {
    font-size: 1rem;
    color: var(--primary);
  }
}
